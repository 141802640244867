<template>
  <header
    class="flex items-center bg-gray-100 p-4 md:p-5 xl:p-6 border-b border-gray-200"
  >
    <slot></slot>
  </header>
</template>

<script>
export default {
  name: 'CardHeader'
}
</script>
